import React, { useContext } from "react"
import PropTypes from "prop-types"
import "lazysizes"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"

import addToSchema from "../services/addToSchema"

import CustomButton from "../components/buttons/CustomButton"
import VideoEmbed from "../components/VideoEmbed"

const useStyles = makeStyles(() => ({
  placeholderMediaClass: {
    height: 500,
  },
  videoMediaClass: {
    height: 500,
  },
}))

const getSchema = testimonial => {
  let schema = testimonial.data.schema_json.text || ""

  schema = addToSchema(schema, {
    "@type": "VideoObject",
    embedurl: testimonial.data.video.embed_url,
    description: testimonial.data.description.text,
    name: "Coronarografie | MONZA ARES | Inovație în cardiologie",
    thumbnailurl: testimonial.data.video.thumbnail_url,
    uploaddate: testimonial.first_publication_date,
  })

  return schema
}

const TestimonialPage = ({
  data: { proceduresPages, testimonial },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()

  return (
    <>
      <SEO
        schema={getSchema(testimonial)}
        ogp={testimonial.data.ogp_tags || ""}
        meta={{
          title: testimonial.data.meta_title,
          description: testimonial.data.meta_description,
          keywords: testimonial.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={testimonial.data.noindex}
      />
      <Container maxWidth="lg">
        <main>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Typography variant="h4" component="h1">
            {testimonial.data.title.text}
          </Typography>
          <Typography variant="h5" component="h2">
            {testimonial.data.title.text}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: testimonial.data.description.html,
            }}
          />
          <br />
          <CustomButton
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to={`/${proceduresPage.uid}/${testimonial.data.procedure.document.data.categories[0].category.document.uid}/${testimonial.data.procedure.document.uid}/`}
          >
            {i18n.translations.viewProcedure}
          </CustomButton>
          <br />
          <br />
          <VideoEmbed
            video={testimonial.data.video}
            placeholderMediaClass={classes.placeholderMediaClass}
            videoMediaClass={classes.videoMediaClass}
          />
        </main>
      </Container>
    </>
  )
}

export default TestimonialPage

export const query = graphql`
  query TestimonialPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }

    testimonial: prismicTestimonial(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        description {
          html
          text
        }
        procedure {
          document {
            ... on PrismicProcedure {
              uid
              data {
                categories {
                  category {
                    document {
                      ... on PrismicProcedureCategory {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        video {
          embed_url
          thumbnail_url
          title
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
      first_publication_date(formatString: "YYYY-MM-DD")
    }
  }
`

TestimonialPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    proceduresPages: PropTypes.object.isRequired,
    testimonial: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
